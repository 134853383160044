<template>
  <div>
    <div class="row">
      <div class="col-6">
        <section class="row">
          <div class="col-12">
            <validation-provider
              v-slot="validationContext"
              :rules="{ isImageFile }"
              class="flex-grow-1"
              name="File"
            >
              <label>Thumbnail</label>
              <b-form-file
                v-model="profilePicture"
                :state="getValidationState(validationContext)"
                accept=".jpg, .jpeg, .png, .webp"
                drop-placeholder="Drop file here..."
                placeholder="Choose an image or drag one here..."
                @input="selectProfilePicture"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <small>
                This will replace your existing profile picture. This action can
                not be undone.
              </small>
            </validation-provider>
          </div>
          <div
            v-if="profilePictureChanged"
            class="mt-2 d-flex justify-content-between align-items-center"
          >
            <b-button
              :disabled="loading"
              class="mr-1"
              variant="success"
              @click="updateProfilePicture"
            >
              Save
            </b-button>
            <b-button
              :disabled="loading"
              class=""
              variant="danger"
              @click="cancel"
            >
              Cancel
            </b-button>
          </div>
        </section>
      </div>
      <div class="col-6 d-flex justify-content-center align-items-center">
        <div v-if="profilePictureURL" class="profile-picture">
          <img v-if="profilePictureURL" :src="profilePictureURL" alt="">
        </div>
        <div v-else class="d-flex flex-column align-items-center mt-5">
          <img alt="" src="@/assets/images/icons/jpg.png">
          <div class="mt-2">No profile picture selected</div>
        </div>
      </div>
    </div>

    <b-modal
      v-if="profilePicture"
      id="image-cropper-modal"
      hide-footer
      size="lg"
      title="Crop Image"
    >
      <thumbnail-cropper-modal
        :chosen-image="profilePictureBase64"
        :file-type="profilePicture.type"
        @close="cancel"
        @crop="cropImage"
      />
    </b-modal>
  </div>
</template>

<script>
import { validatorIsImageFile } from "@/@core/utils/validations/validators";
import { isImageFile } from "@/@core/utils/validations/validations";
import CountryService from "@/services/CountryService";
import ProfileService from "@/services/ProfileService";
import ThumbnailCropperModal from "@/views/theHub/sections/ThumbnailCropperModal.vue";

export default {
  components: { ThumbnailCropperModal },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      profilePicture: null,
      profilePictureBase64: "",
      profilePictureURL: "",
      profilePictureChanged: false,
    };
  },
  computed: {
    isImageFile() {
      return isImageFile;
    },
  },
  watch: {
    profilePicture() {
      if (validatorIsImageFile(this.profilePicture)) {
        this.profilePictureURL = URL.createObjectURL(this.profilePicture);
        this.profilePictureChanged = true;
      }
    },
  },
  beforeMount() {
    this.checkExistingProfilePicture();
  },
  methods: {
    checkExistingProfilePicture() {
      // Check if we have a profile picture
      if (
        this.$props.profile.profile_picture &&
        this.$props.profile.profile_picture.file_location !== ""
      ) {
        this.profilePictureURL = `${CountryService.getApiUrl()}/files/${
          this.$props.profile.profile_picture.file_location
        }/${this.$props.profile.profile_picture.filename}`;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async updateProfilePicture() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      try {
        await ProfileService.uploadProfilePicture(this.profilePicture);

        this.$toast.success("Updated profile picture successfully", {
          toastClassName: ["toast-std", "success-toast"],
        });

        this.$emit("refresh");
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not update profile picture, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.profilePictureURL = "";
      this.profilePictureChanged = false;
      this.profilePicture = null;

      this.checkExistingProfilePicture();
    },
    async selectProfilePicture() {
      if (validatorIsImageFile(this.profilePicture)) {
        this.profilePictureBase64 = await this.toBase64(this.profilePicture);
        this.$bvModal.show("image-cropper-modal");
      }
    },
    async toBase64(file) {
      if (!file) return null;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    cropImage(croppedURL, blob) {
      this.profilePicture = new File(
        [blob],
        "image." + blob.type.split("/")[1],
        {
          type: blob.type,
        }
      );
      this.profilePictureURL = croppedURL;
      this.profilePictureChanged = true;
      this.$bvModal.hide("image-cropper-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-picture {
  border-radius: 50%;
  overflow: hidden;
  width: 65%;
  aspect-ratio: 1/1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
